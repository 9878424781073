// Header
.hero {
  .hero-wrap {
    margin-top: toRem(166);
    margin-bottom: toRem(80);
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: toRem(64);

    @include media-breakpoint-down(md) {
      margin-top: toRem(136);
      margin-bottom: toRem(64);
      gap: toRem(48);
    }

    @include media-breakpoint-down(sm) {
      padding-inline: toRem(20);
    }

    .hero__text {
      text-align: center;
      max-width: 664px;
      display: flex;
      flex-direction: column;
      gap: toRem(24);

      @include media-breakpoint-down(md) {
        gap: toRem(16);
        margin-bottom: toRem(32);
      }

      h1 {
        color: $color-white;
      }

      p {
        color: $color-lighter-grey;
      }
    }
  }
}

// Card Pricing
.hero {
  &__card {
    --bs-gutter-y: 0;
    --bs-gutter-x: 24px;

    & > :not(:nth-child(2)) {
      margin-top: toRem(64);

      @include media-breakpoint-down(md) {
        margin-top: 0;
      }
    }

    @include media-breakpoint-down(md) {
      --bs-gutter-y: 24px;
      --bs-gutter-x: 0;
    }
  }
}
