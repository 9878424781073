// Initialize first
@import '../init';

// Import files
@import 'pricing';

// Add your style here
.pricing-v2 {
  // Section Hero
  .hero {
    .hero-wrap {
      .hero__nav {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: toRem(64);

        // Menu Custom
        .nav-pricing {
          background-color: $color-white;
          padding: toRem(4);
          border-radius: toRem(10);

          .btn--medium {
            color: $color-black;
            border-radius: toRem(10);
            transition: all 0.2s;

            &:hover {
              color: $color-purple;
            }

            &.active {
              background-color: unset;
              background-image: $gradient-purple;
              color: $color-white;
            }
          }
        }

        // Card Adjusment
        .tab-content {
          width: 100%;
        }
      }
    }
  }

  // Section Feature
  .feature {
    position: relative;
    // Pattern
    .pattern {
      width: 1714.5px;
      aspect-ratio: 2.1 / 1;
      position: absolute;
      top: toRem(5);
      left: toRem(-56);
      background-image: url('../../media/images/patterns-and-ornaments/pricing-v2-pattern.svg');
      background-position: center;
      background-size: contain;
      z-index: -1;
    }

    // Content
    .container {
      padding-top: toRem(80);
      padding-bottom: toRem(80);
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: toRem(64);

      @include media-breakpoint-down(md) {
        padding-top: toRem(64);
        padding-bottom: toRem(64);
        gap: toRem(48);
      }

      @include media-breakpoint-down(sm) {
        padding-inline: toRem(20);
      }

      .feature__header {
        color: $color-dark-deep-blue;

        @include media-breakpoint-down(md) {
          text-align: center;
        }
      }

      // Table
      .check {
        width: 24px;
        aspect-ratio: 1 / 1;
        background-image: url('../../media/images/icons/checkmark.svg');
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
      }

      // Table Custom
      .feature__table {
        padding: toRem(48) toRem(64);
        width: 100%;
        background-color: $color-white;
        box-shadow: $shadow-lg;

        @include media-breakpoint-down(md) {
          padding: toRem(32) toRem(16);
        }

        .table {
          display: flex;
          flex-direction: column;
          margin-bottom: toRem(32);

          @include media-breakpoint-down(lg) {
            margin-bottom: 0;
          }

          // Table Header
          .table-list-wrap {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            padding: toRem(20) 0;
            grid-column-gap: toRem(128);
            border-bottom: solid 1px $color-lighter-grey;
            text-align: center;

            @include media-breakpoint-down(lg) {
              flex-wrap: wrap;
              column-gap: 0;
            }
          }

          .table-list-header {
            padding: 0;
            padding-bottom: toRem(32);

            @include media-breakpoint-down(lg) {
              padding-bottom: 0;
            }
          }

          .table-header {
            flex-basis: 33.3333%;
          }

          .table-list-left {
            text-align: left;
            flex: 1;
            flex-basis: 33.3333%;

            @include media-breakpoint-down(lg) {
              text-align: center;
              width: 100%;
              flex: 0 0 100%;
              margin-bottom: toRem(8);
            }

            p {
              padding: 0;
            }
          }

          .table-list-menu {
            @include media-breakpoint-down(lg) {
              padding-bottom: toRem(20);
              margin-top: toRem(20);
              padding-top: toRem(20);
              border-top: solid 1px $color-lighter-grey;
              order: 1;
              flex: 0 0 100%;
              text-align: center;
            }

            &--no-top {
              margin-top: 0;
              border-top: unset;
              margin-bottom: 0;
            }
          }

          .table-check {
            flex-basis: 33.3333%;
            display: flex;
            align-items: center;
            justify-content: center;

            @include media-breakpoint-down(lg) {
              width: 33%;
            }
          }

          .table-category {
            color: $color-grey;
          }
        }
      }
    }
  }
}
